import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  Input,
} from "@angular/core";
import { AuthService } from "src/app/services/auth/auth.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { RoleName } from "src/app/interfaces/buyer";
import { BuyerService } from "src/app/services/buyer.service";
import { SupplierService } from "src/app/services/supplier.service";

@Component({
  selector: "app-system-admin",
  templateUrl: "./system-admin.component.html",
  styleUrls: ["./system-admin.component.sass"],
})
export class SystemAdminComponent implements OnInit {
  @Input() enableClick = true;
  isShowDrop: boolean;
  isQC: boolean;
  isSST: boolean;
  isSystemAdmin: boolean;
  isBuyerAdmin: boolean;
  buyerData: any[] = [];
  supplierData: any[] = [];
  @ViewChild("abc")
  abc: ElementRef;

  @ViewChild("def")
  def: ElementRef;

  constructor(
    private authService: AuthService,
    private router: Router,
    private ele: ElementRef,
    private buyerService: BuyerService,
    private supplierService: SupplierService
  ) {}

  ngOnInit() {
    this.isShowDrop = this.isQC = this.isSST = this.isSystemAdmin = this.isBuyerAdmin = false;
  }

  @HostListener("document:keydown", ["$event"])
  documentClick(evt) {
    if (this.abc && evt.keyCode !== 13) {
      if (this.def.nativeElement.contains(evt.target)) {
        return;
      }
      if (this.abc.nativeElement.contains(evt.target)) {
        return;
      }
      this.isShowDrop = false;
    }
    if(evt.keyCode == 13 && evt.srcElement.nodeName === 'APP-SYSTEM-ADMIN') {
      this.isShowDrop = !this.isShowDrop;
    }
  }

  navigateTo(item: string) {
    // console.log(item);
    this.router.navigate([`${item}`]);
  }

  async CelonisBuyerExtract() {
    await this.getBuyerData(); 
    const filename = 'sh_celonis_missing_buyer_extract_view.csv'
    if (this.buyerData.length > 0) {
      const csvData = this.convertToCSV(this.buyerData);
      this.downloadCSV(csvData,filename);
    } else {
      console.error('No data available to export.');
    }
  }
  async CelonisSupplyerExtract() {
    await this.getSupplierData(); 
    const filename = 'sh_celonis_missing_supplier_extract_view.csv'
    if (this.buyerData.length > 0) {
      const csvData = this.convertToCSV(this.supplierData);
      this.downloadCSV(csvData,filename);
    } else {
      console.error('No data available to export.');
    }
  }



  async getBuyerData() {
    try {
      const data = await this.buyerService.getCelonisBuyerExtract();
      this.buyerData = Array.isArray(data) ? data : [];
    } catch (error) {
      console.error('Error fetching RA Status:', error);
    }
  }
  async getSupplierData() {
    try {
      const data = await this.supplierService.getCelonisSupplierExtract();
      this.supplierData = Array.isArray(data) ? data : [];
    } catch (error) {
      console.error('Error fetching RA Status:', error);
    }
  }



  convertToCSV(jsonData) {
    if (jsonData.length === 0) return '';
    
    const headers = Object.keys(jsonData[0]);
    const rows = jsonData.map(row => 
      headers.map(header => JSON.stringify(row[header], (_, value) => value ?? '')).join(',')
    );
    
    return [headers.join(','), ...rows].join('\n');
  }

   downloadCSV(csvContent, fileName) {
    const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const downloadUrl = URL.createObjectURL(csvBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = downloadUrl;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(downloadUrl);
}

  checkPermission() {
    if (environment.role === "buyer") {
      this.isSST = this.authService.passport.buyer.RoleCode === RoleName.APSupplierSetupTeam;
      this.isQC = this.authService.passport.buyer.RoleCode === RoleName.APQualityControl;
      this.isSystemAdmin = this.authService.passport.buyer.RoleCode === RoleName.SystemAdmin;
      this.isBuyerAdmin = this.authService.passport.buyer.RoleCode === RoleName.BuyerAdmin;
    }
  }
}
