import { Injectable } from "@angular/core";
import { HttpService } from "./api/http.service";
import { environment } from "../../environments/environment";
import { SupplierModel } from "../interfaces/supplierModel";
import { first } from "rxjs/operators";
import { SupplierHiperosLogMessage } from "src/app/interfaces/supplierHiperosLogMessage";
import {
  AuditChangeRequestDetail,
  BuyerChangeRequestDTO,
} from "../interfaces/changeRequest";
import { MigratedInvitation } from "../interfaces/MigratedInvitation";
import { CompanyCode } from "../interfaces/requestForm";
import { FileUploader } from "ng2-file-upload";
import { SupplierModelForSaveDraft } from "../interfaces/supplierModelForSaveDraft";
import { SupplierMappingDTO } from "../interfaces/mapping";
@Injectable()
export class BuyerService {
  baseUrl = environment.gateway;
  buyerUrl = environment.buyerGateway;
  stakeholderUrl = environment.stakeholderGateway;
  roleItemPermissions = {};

  constructor(private http: HttpService) {}

  public initBuyer(email: string) {
    const url = `${this.baseUrl}/buyer/init`;
    const data = { email };
    return this.http.PostPromise(url, data);
  }

  public syncBuyer(email: string, name: string) {
    const url = `${this.baseUrl}/buyer/sync`;
    const data = { email, name };
    return this.http.PostPromise(url, data);
  }

  public onBoardingRelationshipGet(supplierCompanyNb: string): Promise<any> {
    const url = `${this.baseUrl}/buyer/onboarding/accenture/${supplierCompanyNb}`;
    return this.http.GetPromise(url);
  }

  public onBoardingGet(
    supplierCompanyNb: string,
    infoType: string
  ): Promise<any> {
    const url = `${this.baseUrl}/buyer/onboarding/accenture/${supplierCompanyNb}/${infoType}`;
    return this.http.GetPromise(url);
  }

  public onBoardingCreate(supplierCompanyNb: string): Promise<any> {
    const url = `${this.baseUrl}/buyer/onboarding/accenture/${supplierCompanyNb}`;
    return this.http.PostPromiseTimeOut(url, null);
  }

  public onBoardingPost(
    supplierCompanyNb: string,
    infoType: string,
    model: any
  ): Promise<any> {
    const url = `${this.baseUrl}/buyer/onboarding/accenture/${supplierCompanyNb}/${infoType}`;
    return this.http.PostPromise(url, model);
  }

  public onBoardingStatus(model: any): Promise<any> {
    const url = `${this.baseUrl}/buyer/onboarding/status`;
    return this.http.PostPromise(url, model);
  }

  public onBoardingPut(
    supplierCompanyNb: string,
    infoType: string,
    model: any
  ): Promise<any> {
    const url = `${this.baseUrl}/buyer/onboarding/accenture/${supplierCompanyNb}/${infoType}`;
    return this.http.PostPromise(url, model);
  }

  public onBoardingHistoryPost(supplierCompanyNb: string): Promise<any> {
    const url = `${this.baseUrl}/buyer/onboarding/history`;
    return this.http.PostPromise(url, {
      buyerCompanyNb: "accenture",
      supplierCompanyNb: supplierCompanyNb,
      buyerOrgName: "Buyer",
      supplierOrgName: supplierCompanyNb,
    });
  }

  public permissionByRoleGet(
    roleName: string,
    code: string,
    permissionType: string,
    isPublic: string
  ): Promise<any> {
    const url = `${this.baseUrl}/permission/${roleName}/${code}/${permissionType}/${isPublic}`;
    return this.http.GetPromise(url);
  }
  public newSupplierSearch(searchTerm): Promise<any> {
    const url = `${this.buyerUrl}/buyer/dashboard/search`;
    return this.http.PostPromise(url, searchTerm);
  }
  public supplierSearch(searchTerm): Promise<any> {
    const url = `${this.baseUrl}/buyer/dashboard/search`;
    return this.http.PostPromise(url, searchTerm);
  }
  public supplierSearchTsm(searchTerm): Promise<any> {
    const url = `${this.baseUrl}/buyer/dashboard-search/search`;
    return this.http.PostPromise(url, searchTerm);
  }

  public getGlobalFieldData(param): Promise<any> {
    const url = `${this.baseUrl}/buyer/getGlobalQuestionAnswers`
    return this.http.PostPromise(url, param);
  }

  public getSupplierPublic(companyNb: string): Promise<any> {
    const url = `${this.baseUrl}/buyer/supplierPublicInfo/${companyNb}`;
    return this.http.GetPromise(url);
  }

  public getSupplierPublicHistory(companyNb: string): Promise<any> {
    const url = `${this.baseUrl}/buyer/supplierHistory/${companyNb}`;
    return this.http.GetPromise(url);
  }
  public buyerUsers() {
    const url = `${this.baseUrl}/buyer/management/users`;
    return this.http.GetPromise(url);
  }

  public userExists(email) {
    const url = `${this.baseUrl}/buyer/management/user/exists/${email}`;
    return this.http.GetPromise(url);
  }

  public createUser(user) {
    const url = `${this.baseUrl}/buyer/management/user/create`;
    return this.http.PostPromise(url, user);
  }

  public modifyUser(user, email) {
    const url = `${this.baseUrl}/buyer/management/user/modify/${email}`;
    return this.http.PostPromise(url, user);
  }

  public buyerRoles() {
    const url = `${this.baseUrl}/buyer/management/roles`;
    return this.http.GetPromise(url);
  }

  public buyerLatestNotification() {
    const url = `${this.baseUrl}/buyer/notification/latest/accenture`;
    return this.http.GetPromise(url);
  }

  public notificationList(userID: number, cp: number, ps: number) {
    const url = `${this.baseUrl}/buyer/myNotifications`;
    return this.http.PostPromise(url, { userID, cp, ps });
  }

  public markAsRead(userID: number, notificationID: string) {
    const url = `${this.baseUrl}/buyer/notifications/markAsRead`;
    return this.http.PostPromise(url, { userID, notificationID });
  }

  // public updateUserStatus(email: string, status: string) {
  //   const url = `${this.baseUrl}/buyer/landing/${email}/${status}`
  //   return this.http.GetPromise(url)
  // }

  /// ############# request form ##########
  public queryRequestForm(supplierCode: string): Promise<SupplierModel> {
    const url =
      `${this.baseUrl}/buyer/requestor_form/${supplierCode}` +
      "?num=" +
      Math.random();
    return this.http.GetPromise(url);
  }
  public queryForNewRequestForm(supplierCode: string): Promise<SupplierModel> {
    const url =
      `${this.buyerUrl}/buyer/requestForm/${supplierCode}` +
      "?num=" +
      Math.random();
    return this.http.GetPromise(url);
  }

  public saveRequestForm(form: SupplierModel) {
    const url = `${this.baseUrl}/buyer/requestor_form`;
    return this.http.PostPromise(url, form);
  }

  public checkEmail(email: string, tsmId: string) {
    email = encodeURIComponent(email);
    const url = `${this.baseUrl}/buyer/check_email/${email}/${tsmId}`;
    return this.http.GetPromise(url);
  }

  public checkCompanyName(companyName: string) {
    companyName = encodeURIComponent(companyName);
    const url = `${this.baseUrl}/buyer/check_companyName/${companyName}`;
    return this.http.GetPromise(url);
  }

  public updateUserStatus(email: string) {
    const url = `${this.baseUrl}/buyer/landing/status/${email}`;
    return this.http.PostPromise(url, email);
  }
  public updateUserAcceptStatus(email: string) {
    const url = `${this.baseUrl}/buyer/landing/acceptStatus/${email}`;
    return this.http.PostPromise(url, email);
  }

  public checkVersionOfHiperos(supplierCode: string): Promise<any> {
    const url = `${this.baseUrl}/buyer/checkVersionOfHiperos/${supplierCode}`;
    return this.http.GetPromise(url);
  }

  public existingsearchPeopleUsingGraphAPI(email: any, level: any = undefined) {
    const url = `${this.baseUrl}/buyer/searchPeople/${email}`;
    return this.http.GetPromise(url);
  }

  public searchPeopleUsingGraphAPI(email: any, level: any = undefined) {
    const url = `${this.buyerUrl}/buyer/searchPeople/${email}`;
    return this.http.GetPromise(url);
  }
  /// ############# end request form ######

  public buyerFileUpload(supplierCode, fileName: string, files): Promise<any> {
    const url = `${this.baseUrl}/buyer/file/upload/${supplierCode}`;
    const formData: FormData = new FormData();
    files.forEach((file) => {
      formData.append(fileName, file._file, file._file.name);
    });
    return this.http.PostPromise(url, formData);
  }

  public buyerFileDownload(supplierCode, key, fileName): Promise<any> {
    const url = `${this.baseUrl}/buyer/file/download/${supplierCode}`;
    const data = { key, fileName };
    return this.http.PostPromise(url, data);
  }

  /// ############# start profile form ######
  public getProfilesForm(supplierCode: string) {
    const url = `${this.baseUrl}/buyer/profile_form/${supplierCode}`;
    return this.http.GetPromise(url);
  }

  public queryProfilesFormByACL(supplierCode, roleCode: string) {
    const url = `${this.baseUrl}/buyer/profile_form/${supplierCode}`;
    const data = { roleCode };
    return this.http.PostPromise(url, data);
  }
  /// ############# end profile form ######

  /// ############# start buyer ACL ######
  public async queryBuyerPagesPermission(roleCode: string) {
    if (this.roleItemPermissions[roleCode]) {
      return this.roleItemPermissions[roleCode];
    }
    const url = `${this.baseUrl}/buyer/permission/page`;
    const data = { roleCode };
    this.roleItemPermissions[roleCode] = await this.http.PostPromise(url, data);
    return this.roleItemPermissions[roleCode];
  }
  /// ############# end buyer ACL ######

  public querySupplierHistories(supplierCode: string): Promise<any> {
    const url = `${this.baseUrl}/supplier/histories/${supplierCode}`;
    return this.http.GetPromise(url);
  }

  public queryBuyerHistories(supplierCode: string): Promise<any> {
    const url = `${this.baseUrl}/buyer/histories/${supplierCode}`;
    return this.http.GetPromise(url);
  }
  public queryNewBuyerHistories(supplierCode: string): Promise<any> {
    const url = `${this.buyerUrl}/buyer/histories/${supplierCode}`;
    return this.http.GetPromise(url);
  }
  public queryChangeRequestHistories(supplierCode: string): Promise<any> {
    const url = `${this.baseUrl}/buyer/changeRequest/${supplierCode}`;
    return this.http.GetPromise(url);
  }

  public sendInvitation(form: SupplierModel, cc: string, type = "invitation") {
    const url = `${this.baseUrl}/buyer/send/invitation/${type}`;
    return this.http.PostPromise(url, { form, cc });
  }

  public checkRiskAssessment(riskKey) {
    riskKey = encodeURIComponent(riskKey);
    const url = `${this.baseUrl}/buyer/riskassessment/relationship`;
    return this.http.PostPromise(url, { riskKey });
  }

  public importRiskAssessment(files) {
    const url = `${this.baseUrl}/buyer/management/importRiskAssessment`;
    const formData: FormData = new FormData();
    files.forEach((file) => {
      formData.append("riskAssessmentFile", file._file, file._file.name);
    });
    return this.http.PostPromise(url, formData);
  }

  public importForDataMigration(files) {
    const url = `${this.baseUrl}/buyer/management/dataMigrationTSM`;
    const formData: FormData = new FormData();
    files.forEach((file) => {
      formData.append("dataMigrationFile", file._file, file._file.name);
    });
    return this.http.PostPromise(url, formData);
  }

  public terminateValueList(termLists, files) {
    const url = `${this.baseUrl}/buyer/management/terminateValueList`;
    const termData = { termLists: termLists };
    // console.log("termData", termData);
    return this.http.PostPromise(url, termData);
  }

  public resendRiskAssessment(supplierCode: string) {
    const url = `${this.baseUrl}/buyer/riskassessment/retry/${supplierCode}`;
    return this.http.GetPromise(url);
  }

  public listRetryItems() {
    const url = `${this.baseUrl}/buyer/riskassessment/retryItems`;
    return this.http.GetPromise(url);
  }

  public listRetryEmail() {
    const url = `${this.baseUrl}/buyer/resendEmails/listAllResendEmails`;
    return this.http.GetPromise(url);
  }

  public resendEmail(data) {
    const url = `${this.baseUrl}/buyer/resendEmails/resendEmail`;
    return this.http.PostPromise(url, data);
  }

  public queryRiskAssessment(supplierCode: string) {
    const url = `${this.baseUrl}/buyer/riskassessment/supplier/${supplierCode}`;
    return this.http.GetPromise(url);
  }

  public updateOnboardingStatus(form: SupplierModel) {
    const url = `${this.baseUrl}/buyer/update/mappingStatus`;
    return this.http.PostPromise(url, form);
  }

  public addRiskAssessmentLog(hiperosLog: SupplierHiperosLogMessage) {
    const url = `${this.baseUrl}/buyer/riskassessment/addLog`;
    return this.http.PostPromise(url, hiperosLog);
  }

  public getChangeRequestList(supplierCode: string) {
    const url = `${this.baseUrl}/buyer/cr/list/${supplierCode}`;
    return this.http.GetPromise(url);
  }

  public updateChangeRequestforSST(changeRequest: BuyerChangeRequestDTO) {
    const url = `${this.baseUrl}/buyer/cr/sstUpdate`;
    return this.http.PostPromise(url, changeRequest);
  }

  // when send  migrated invitation save migratedInvitation in MigratedInvitation table
  public saveMigratedInvitation(migratedInvitation: MigratedInvitation) {
    const url = `${this.baseUrl}/buyer/saveMigratedInvitation`;
    return this.http.PostPromise(url, migratedInvitation);
  }

  public getPaymentTermList(componyCode: any) {
    const url = `${this.baseUrl}/paymentTerms`;
    return this.http.PostPromise(url, componyCode);
  }
  public deleteUserInfo(supplierCode: any, supplierEmail: any) {
    const url = `${this.baseUrl}/buyer/ops/deleteInfo`;
    const data = { supplierCode: supplierCode, supplierEmail: supplierEmail };
    return this.http.PostPromise(url, data);
  }

  // ------------------------------------------------------------------------------------------ //

  // workflow requestor form router
  public saveRequestFormDraft(form: SupplierModel) {
    const url = `${this.baseUrl}/buyer/requestorForm/saveDraft`;
    return this.http.PostPromise(url, form);
  }

  public saveRequestorDetails(form: SupplierModelForSaveDraft) {
    const url = `${this.buyerUrl}/buyer/requestordetails/saveDraft`;
    return this.http.PostPromise(url, form);
  }

  public saveRequestFormInfo(form: SupplierModelForSaveDraft) {
    const url = `${this.buyerUrl}/buyer/requestorformInfo/savedraft`;
    return this.http.PostPromise(url, form);
  }

  public submitRequestFormDraft(form: SupplierModel) {
    const url = `${this.baseUrl}/buyer/requestorForm/submitDraft`;
    return this.http.PostPromise(url, form);
  }

  public submitRequestFormDraftEnhanced(form: SupplierModel) {
    const url = `${this.buyerUrl}/buyer/requestorForm/submitDraft`;
    return this.http.PostPromise(url, form);
  }

  public resubmitRequestForm(form: SupplierModel) {
    const url = `${this.baseUrl}/buyer/requestorForm/resubmit`;
    return this.http.PostPromise(url, form);
  }

  public resubmitRequestFormEnhanced(form: SupplierModel) {
    const url = `${this.buyerUrl}/buyer/requestorForm/resubmit`;
    return this.http.PostPromise(url, form);
  }

  public changeRequestForm(form: SupplierModel) {
    const url = `${this.baseUrl}/buyer/requestorForm/changeRequest`;
    return this.http.PostPromise(url, form);
  }

  public requestFormTerminate(form: SupplierModel) {
    const url = `${this.baseUrl}/buyer/requestorForm/terminate`;
    return this.http.PostPromise(url, form);
  }

  //merge duplicate suppliers
  public mergeDuplicateSuppliers(form: any) {
    const url = `${this.baseUrl}/mergeDuplicateSuppliers`;
    return this.http.PostPromise(url, form);
  }

  // workflow buyer router
  public sstApprove(form: SupplierModel) {
    const url = `${this.baseUrl}/buyer/apReview/sstApprove`;
    return this.http.PostPromise(url, form);
  }

  public sstApproveEnhanced(form: SupplierMappingDTO) {
    const url = `${this.stakeholderUrl}/apReview/sstApprove`;
    return this.http.PostPromise(url, form);
  }

  public apRejectToBuyer(form: SupplierModel) {
    const url = `${this.baseUrl}/buyer/apReview/sstReject/toBuyer`;
    return this.http.PostPromise(url, form);
  }

  public apRejectToBuyerEnhanced(form: SupplierMappingDTO) {
    const url = `${this.stakeholderUrl}/apReview/sstReject/toBuyer`;
    return this.http.PostPromise(url, form);
  }

  public apRejectToSupplier(form: SupplierModel) {
    const url = `${this.baseUrl}/buyer/apReview/sstReject/toSupplier`;
    return this.http.PostPromise(url, form);
  }

  public apRejectToSupplierEnhanced(form: SupplierMappingDTO) {
    const url = `${this.stakeholderUrl}/apReview/sstReject/toSupplier`;
    return this.http.PostPromise(url, form);
  }

  public sstTerminate(form: SupplierModel) {
    const url = `${this.baseUrl}/buyer/apReview/sstTerminate`;
    return this.http.PostPromise(url, form);
  }

  public qcApprove(form: SupplierModel) {
    const url = `${this.baseUrl}/buyer/apReview/qcApprove`;
    return this.http.PostPromise(url, form);
  }

  public qcApproveEnhanced(form: SupplierMappingDTO) {
    const url = `${this.stakeholderUrl}/apReview/qcApprove`;
    return this.http.PostPromise(url, form);
  }

  public qcReject(form: SupplierModel) {
    const url = `${this.baseUrl}/buyer/apReview/qcReject`;
    return this.http.PostPromise(url, form);
  }

  public qcRejectEnhanced(form: SupplierMappingDTO) {
    const url = `${this.stakeholderUrl}/apReview/qcReject`;
    return this.http.PostPromise(url, form);
  }

  public qcTerminate(form: SupplierModel) {
    const url = `${this.baseUrl}/buyer/apReview/qcTerminate`;
    return this.http.PostPromise(url, form);
  }

  public saveBI(form: SupplierModel) {
    const url = `${this.baseUrl}/buyer/biReview/saveBI`;
    return this.http.PostPromise(url, form);
  }

  public terminateSupplier(form: SupplierModel) {
    const url = `${this.baseUrl}/buyer/terminateSupplier`;
    return this.http.PostPromise(url, form);
  }
  public sendDataToSap(model: any) {
    const url = `${this.baseUrl}/sapIntegrate/sendMessageToSAP`;
    return this.http.PostPromise(url, model);
  }
  public getRiskAssessmentBysupplierCode(supplierCode: string) {
    const url = `${this.baseUrl}/buyer/getRiskassessment/supplier/${supplierCode}`
    return this.http.GetPromise(url)
  }

  public getCelonisBuyerExtract() {
    const url = `${this.baseUrl}/getCelonisBuyerExtract`;
    return this.http.GetPromise(url);
  }
}
