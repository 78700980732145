import { Component } from "@angular/core";
import { SupplierStatus } from "src/app/interfaces/mapping";
import { BuyerService } from "src/app/services/buyer.service";
import { SupplierService } from "src/app/services/supplier.service";

@Component({
  selector: "app-merge-profiles",
  templateUrl: "./merge-profiles.component.html",
  styleUrl: "./merge-profiles.component.sass",
})
export class MergeProfilesComponent {
  errorMsg: string = "";
  primarySupplierCode: string = "";
  primarySupplierName: string = "";
  secondarySupplierCode: string = "";
  secondarySupplierName: string = "";
  primarySupplierStatus: string = "";
  secondarySupplierStatus: string = "";
  showConfirmationBox: boolean = false;
  updatedBy: string = "Merge Profiles Process";
  statusMsg: string = "";
  statusMsgDescription: string = "";
  isYesClicked: boolean = false;
  disableYes: boolean = false;

  constructor(
    private supplierService: SupplierService,
    private buyerService: BuyerService
  ) {}

  getErrorMsg(errorType: string): string {
    let errorMsg = "";
    switch (errorType) {
      case "INVALID":
        errorMsg = "Please enter valid supplier code.";
        break;
      case "MERGED":
        errorMsg = "This secondary profile is already merged.";
        break;
      case "TERMINATED":
        errorMsg = "Please enter valid supplier code which is Active.";
        break;
      case "IDENTICAL":
        errorMsg =
          "The Primary and Secondary Profile supplier codes provided are identical. Please input distinct supplier codes for each.";
        break;
      default:
        errorMsg = "";
    }
    return errorMsg;
  }

  async getPrimarySupplierName() {
    if (this.primarySupplierCode.length === 13) {
      try {
        const primarySupplierDetails: any =
          await this.supplierService.getSupplierInfo(this.primarySupplierCode);
        this.primarySupplierName =
          primarySupplierDetails?.data[0]?.searchTerm?.supplierName;
        this.primarySupplierStatus = primarySupplierDetails?.data[0]?.status;
        this.validate();
      } catch (err) {
        console.log(err);
      }
    } else {
      this.primarySupplierName = "";
    }
  }

  async getSecondarySupplierName() {
    if (this.secondarySupplierCode.length === 13) {
      try {
        const secondarySupplierDetails: any =
          await this.supplierService.getSupplierInfo(
            this.secondarySupplierCode
          );
        this.secondarySupplierName =
          secondarySupplierDetails?.data[0]?.searchTerm?.supplierName;
        this.secondarySupplierStatus =
          secondarySupplierDetails?.data[0]?.status;
        this.validate();
      } catch (err) {
        console.log(err);
      }
    } else {
      this.secondarySupplierName = "";
    }
  }

  validate() {
    if (!this.primarySupplierName || !this.secondarySupplierName)
      this.errorMsg = this.getErrorMsg("INVALID");
    else if (
      this.primarySupplierStatus === SupplierStatus.terminated ||
      this.secondarySupplierStatus === SupplierStatus.terminated
    )
      this.errorMsg = this.getErrorMsg("TERMINATED");
    else if (
      this.primarySupplierName.startsWith("DUPLICATE-") ||
      this.secondarySupplierName.startsWith("DUPLICATE-")
    )
      this.errorMsg = this.getErrorMsg("MERGED");
    else if (this.primarySupplierCode === this.secondarySupplierCode)
      this.errorMsg = this.getErrorMsg("IDENTICAL");
    else this.errorMsg = this.getErrorMsg("");
  }

  mergeProfile() {
    if (this.primarySupplierName && this.secondarySupplierName)
      this.showConfirmationBox = this.errorMsg ? false : true;
  }

  mergeProfileNo() {
    this.showConfirmationBox = false;
  }

  async mergeProfileYes() {
    this.disableYes = true;
    const form = {
      primarySupplierCode: this.primarySupplierCode,
      secondarySupplierCode: this.secondarySupplierCode,
      updatedBy: this.updatedBy,
    };
    try {
      const isProfileMerged: any =
        await this.buyerService.mergeDuplicateSuppliers(form);
      if (isProfileMerged && isProfileMerged.isSuccess) {
        this.statusMsg = "Successful";
        this.statusMsgDescription =
          "The Secondary profile is merged into Primary profile successfully.";
        this.isYesClicked = true;
      }
    } catch (err) {
      this.statusMsg = "Error";
      this.statusMsgDescription =
        "Failed to merge the profiles. Please try again later.";
      this.isYesClicked = true;
      console.log(err);
    }
  }

  onClose() {
    this.primarySupplierCode = "";
    this.secondarySupplierCode = "";
    this.primarySupplierName = "";
    this.secondarySupplierName = "";
    this.primarySupplierStatus = "";
    this.secondarySupplierStatus = "";
    this.showConfirmationBox = false;
    this.isYesClicked = false;
    this.disableYes = false;
  }
}
